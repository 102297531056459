import { createStore } from 'vuex';
import persistedStateVuex from 'persisted-state-vuex';
import { _axios } from '@/utils/axios';

persistedStateVuex.config({
  storageKey: 'leadershipVuex',
});
const store = createStore({
  state: {
    accessToken: '',
  },
  getters: {
    getTokenInfo(state) {
      return state.accessToken;
    },
  },
  mutations: {
    setAccessToken(state, payload) {
      state.accessToken = payload.accessToken;
    },
  },
  actions: {},
  plugins: [persistedStateVuex.init],
});

export { store };
