let mainAPi = '';
let carrotApi = '';

if (location.origin.includes('local') || location.origin.includes('stage') || location.origin.includes('192')) {
  mainAPi = 'https://stage-api.carrotleaders.com'; //메인api 스테이지 주소
  carrotApi = 'https://stage-api.carrotleaders.com'; //서브api 스테이지 주소
  // mainAPi = 'http://api.carrotleaders.com'; //메인api 스테이지 주소
} else {
  mainAPi = 'https://api.carrotleaders.com'; //메인api 스테이지 주소
  carrotApi = 'https://www.carrotenglish.kr'; //메인api 운영 주소
}

export { mainAPi, carrotApi };
