import axios from 'axios';
import { mainAPi } from './apiUrl';
import { store } from '@/store/index';

// axios.defaults.timeout = 30000; // ms
// axios.defaults.retry = 4; // count
// axios.defaults.retryDelay = 1000; // ms
let targetUrl = '';
if (location.origin.includes('local') || location.origin.includes('stage') || location.origin.includes('192')) {
  targetUrl = `https://stage-api.carrotleaders.com/`;
} else {
  targetUrl = `https://api.carrotleaders.com/`;
}
axios.defaults.headers.common['Content-Type'] = 'application/json';

const _axios = axios.create({
  baseURL: targetUrl,
  timeout: 30000,
  withCredentials: false,
});

/* 인터셉터 request */
_axios.interceptors.request.use(
  config => {
    const { accessToken } = store.state;

    Object.assign(config.headers, {
      accessToken: accessToken ? accessToken : '',
    });

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/* 인터셉터 response */
// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    if (response.data.errorMessage !== undefined) {
      alert(response.data.errorMessage);
      // window.location.href = "https://carrotians.net";
      // console.error("interceptors.response Message: ", response.data);
    }
    return response;
  },
  function (error) {
    alert('서버와의 연결이 끊어졌습니다. 인터넷을 확인해보시고, 다시 접속해주세요');
    window.location.href = 'carrotians.net';
    console.error('Network Error, Retry', error);
  }
);

export { _axios };
